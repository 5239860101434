import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/redux';
// Async
import { fetchPrices } from 'store/prices/pricesAsync';
// Selectors
import { selectPrices } from 'store/prices/pricesSelectors';
// Components
import Title from 'components/Title';
import PriceItem from './PriceItem';
// MUI
import { makeStyles } from '@mui/styles';
import { Box, Skeleton } from '@mui/material';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate } from 'utilities/Framer';

const Price:React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const prices = useSelector(selectPrices);

  useEffect(() => {
    dispatch(fetchPrices({}));
    // eslint-disable-next-line
  }, []);

  return (
    <motion.section
      data-container="price"
      className={[classes.block, 'container'].join(' ')}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <motion.div
        variants={bottomAnimate}
        custom={1}
      >
        <Title>Ціни</Title>
      </motion.div>
      <Box className={classes.list}>
        {!!prices.length ? (
          prices.map((item, index) => (
            <PriceItem key={item._id} item={item} index={index} />
          ))
        ) : (
          Array.from({length: 3}, (_, i) => i).map(item => (
            <Skeleton
              key={item} variant="rectangular"
              className={classes.skeleton}
            />
          ))
        )}
      </Box>
    </motion.section>
  );
}

export default Price;

const useStyles = makeStyles({
  block: {
    paddingTop: '16px', 
    paddingBottom: '16px',
    '@media (min-width: 600px)': {
      paddingTop: '32px', 
      paddingBottom: '32px',

    },
    '@media (min-width: 900px)': {
      paddingTop: '40px', 
      paddingBottom: '40px',
    },
  },
  list: {
    paddingTop: '40px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '40px',
  },
  skeleton: {
    flexBasis: '100%',
    height: '300px !important',
    borderRadius: '8px',
    '@media (min-width: 900px)': {
      flexBasis: 'calc(100% / 3 - 27px)',
    },
  }
});
