import React, { Fragment, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
// Hooks
import useScrollIntoView from 'hooks/useScrollIntoView';
import useDialog from 'hooks/useDialog';
// Components
import Logo from 'components/Logo';
import SignInForm from 'components/SignInForm';
import SignUpForm from 'components/SignUpForm';
// MUI
import { Button, IconButton, useMediaQuery } from '@mui/material';
import {
  Close as CloseIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
// styles
import classes from './styles.module.scss';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from 'store/auth/authSelectors';
import UserMenu from './UserMenu';

interface INavLink {
  id: string;
  label: string;
  to?: string;
}

const navLinks:INavLink[] = [
  { id: 'main', label: 'Головна' },
  { id: 'about', label: 'Про озеро' },
  { id: 'price', label: 'Ціни' },
  { id: 'gallery', label: 'Галерея' },
  { id: 'reservation', label: 'Бронювання' },
  { id: 'contact-us', label: 'Контакти' },
  { id: 'history', label: 'Історія', to: '/history' },
  { id: 'calendar', label: 'Календар', to: '/calendar' },
];

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 1
};

const Header:React.FC = () => {
  const { scrollTo } = useScrollIntoView();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isTablet = useMediaQuery('(min-width: 600px)');

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenMenu = () => { setOpen(!open) };

  const observerCallback = useCallback((entries:any) => {
    entries.forEach((entry:any, index:number) => {
      if ( entry.isIntersecting ){
        const id = entry.target.dataset.container;
        if ( id ) setActiveMenuItem(id);
      }
    });
  }, []);

  useLayoutEffect(() => {
    if (pathname !== '/') {
      setActiveMenuItem(null);
      return;
    }

    const blocks = navLinks.map((navLink:INavLink) => document.querySelector(`[data-container="${navLink.id}"]`));
    const observer = new IntersectionObserver(observerCallback, options);
    blocks.forEach((block:Element | null) => {
      if ( block ) observer.observe(block)
    });
    return () => {
      observer.disconnect();
    }
    // eslint-disable-next-line
  }, [pathname]);

  const handleScrollTo = (block: string) => {
    if (pathname !== '/') {
      navigate('/');
    }
    setOpen(false);
    setTimeout(() => {
      scrollTo(block);
    }, 100)
  }

  useEffect(() => {
    const body = document.getElementsByTagName('body');
    body[0].className = open ? 'page' : '';
  }, [open]);

  const { Dialog:SignInDialog, openDialog:openSignInDialog, closeDialog:closeSignInDialog } = useDialog();
  const { Dialog:SignUpDialog, openDialog:openSignUpDialog, closeDialog:closeSignUpDialog } = useDialog();

  const handleOpenSignUpDialog = () => {
    closeSignInDialog();
    openSignUpDialog();
  }

  const handleOpenSignInDialog = () => {
    closeSignUpDialog();
    openSignInDialog();
  }

  return (
    <Fragment>
      <SignInDialog dark={true}>
        <SignInForm onClose={closeSignInDialog} openSignUpDialog={handleOpenSignUpDialog} />
      </SignInDialog>

      <SignUpDialog dark={true}>
        <SignUpForm onClose={closeSignUpDialog} openSignInDialog={handleOpenSignInDialog} />
      </SignUpDialog>

      <header className={classes.header}>
        <div className={[classes.header_content, 'container'].join(' ')}>
          <IconButton onClick={handleOpenMenu} className={classes.menuBtn}>
            {open ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Logo />
          <ul className={classNames(classes.menu, { [classes['menu--active']]: open })}>
            {navLinks.slice(1).map((navLink: INavLink) => (
              navLink.to ? (
                <li key={navLink.id} className={classNames(classes.item, { [classes['item--active']]: pathname === navLink.to })}>
                  <NavLink to={navLink.to} onClick={() => setOpen(false)}>{navLink.label}</NavLink>
                </li>
              ) : (
                <li key={navLink.id} className={classNames(classes.item, { [classes['item--active']]: activeMenuItem === navLink.id })} onClick={() => handleScrollTo(navLink.id)}>{navLink.label}</li>
              )
            ))}
          </ul>
          {isAuthenticated ? (
            <UserMenu />
          ) : (
            <Button
              variant="contained"
              onClick={openSignInDialog}
              size={isTablet ? 'medium' : 'small'}
            >
              Увійти
            </Button>
          )}
        </div>
      </header>
    </Fragment>
  );
}

export default Header;
