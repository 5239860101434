import { FC, useMemo, useCallback, useState } from 'react';
// Mui
import { Dialog as MuiDialog, DialogProps } from '@mui/material';

const useDialog = () => {
  const [ open, setOpen ] = useState<boolean>(false);

  const openDialog = useCallback(() => {
    setOpen(true);
    // eslint-disable-nextline
  }, []);

  const closeDialog = useCallback(() => {
    setOpen(false);
    // eslint-disable-nextline
  }, []);

  const Dialog = useMemo(() => {
    const DialogComponent:FC<Omit<DialogProps, 'open' | 'onClose'> & { dark?: boolean }>  = ({ children, dark = false, ...props }) => {
      return (
        <MuiDialog
          {...props}
          open={open}
          onClose={closeDialog}
          fullWidth
          sx={{ backgroundColor: dark ? 'rgba(0,0,0,0.8)' : 'transparent' }}
          PaperProps={{
            style: {
              backgroundColor: dark ? '#000' : '#fff',
              border: dark ? '1px solid #fff' : 'none',
            },
          }}
        >{children}</MuiDialog>
      )
    }
    return DialogComponent;
    // eslint-disable-next-line
  }, [open]);

  return {
    Dialog,
    openDialog,
    closeDialog
  }
}


export default useDialog;