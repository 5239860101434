import { FC, useState } from 'react';
// models
import IPrice from 'models/Price';
// mui
import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
// Framer
import { motion } from 'framer-motion';
import { leftAnimate } from 'utilities/Framer';

type Props = {
  item: IPrice;
  index: number;
};

const PriceItem:FC<Props> = ({ item, index }) => {
  const classes = useStyles();

  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const handleOpenDetails = () => setOpenDetails(prev => !prev);

  const itemBg = `linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 60%), top / cover url(${item.image?.path})`;
    
  return (
    <motion.div
      className={classes.card}
      variants={leftAnimate}
      custom={index + 2}
    >
      <div className={classes.img} style={{ background: itemBg }}>
        <Typography className={classes.title}>{item.title}</Typography>
      </div>
      <div className={classes.actions}>
        <Typography className={classes.subtitle}>{`Ціна: ${item.price} грн`}</Typography>
        <Button
          variant="contained"
          onClick={handleOpenDetails}
          sx={{ zIndex: 2 }}
        >
          {openDetails ? 'Закрити' : 'Деталі'}
        </Button>
      </div>

      <div className={classes.details} style={{
        left: openDetails ? 0 : '-100%',
        opacity: openDetails ? 1 : 0,
      }}>
        <div className={classes.detailsContent}>
          <Typography className={classes.text}>{item.description}</Typography>
        </div>
      </div>
    </motion.div>
  );
}

export default PriceItem;

const useStyles = makeStyles({
  card: {
    position: 'relative',
    flexBasis: '100%',
    border: '1px solid #888',
    borderRadius: '8px',
    overflow: 'hidden',
    '@media (min-width: 900px)': {
      flexBasis: 'calc(100% / 3 - 27px)',
    },
  },
  img: {
    borderRadius: '8px 8px 0 0',
    padding: '12px',
    width: '100%',
    height: '200px',
    borderBottom: '1px solid #888',
    '@media (min-width: 900px)': {
      height: '250px',
    },
  },
  title: {
    fontSize: '20px !important',
    color: '#fefefe',
    fontWeight: 500,
    textShadow: '0 0 3px #000',
  },
  subtitle: {
    fontSize: '18px !important',
    color: '#fefefe',
  },
  text: {
    fontSize: '14px !important',
    color: '#fefefe',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
  },
  actions: {
    padding: '15px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  details: {
    position: 'absolute',
    top: 0,
    left: '-100%',
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    zIndex: 1,
    opacity: 0,
    transition: 'all 0.3s',
    backgroundColor: 'rgba(0, 0, 0, 0.97)',
  },
  detailsContent: {
    width: '100%',
    height: '75%',
    borderBottom: '1px solid #eee',
    overflowY: 'scroll',
    padding: '20px',
    color: '#fefefe',
  },
});
