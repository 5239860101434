import React from 'react';
// Hooks
import useScrollIntoView from 'hooks/useScrollIntoView';
// MUI
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
// Framer
import { motion } from 'framer-motion';
import { leftAnimate, opacityAnimate } from 'utilities/Framer';

export const btnAnimate = {
  hidden: {
    opacity: 0,
    y: -100,
    x: '-50%',
  },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.4, delay: custom * 0.4 }, 
  })
}

const Banner:React.FC = () => {
  const classes = useStyles();

  const { scrollTo } = useScrollIntoView();

  const handleClick = (containerName:string) => {
    scrollTo(containerName);
  }

  return (
    <motion.section
      data-container="main"
      className={classes.block}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <motion.h2
        className={classes.title}
        variants={opacityAnimate}
        custom={1}
      >
        Трофейна Долина
      </motion.h2>
      <motion.p
        className={classes.subtitle}
        variants={leftAnimate}
        custom={2}
      >
        Ласкаво просимо на спортивну водойму Англійського стилю
      </motion.p>
      <motion.div
        className={classes.btnDown}
        variants={btnAnimate}
        custom={3}
      >
        <IconButton onClick={() => handleClick('about')}>
          <ExpandMoreIcon className={classes.btnDownIcon} />
        </IconButton>
      </motion.div>
    </motion.section>
  );
}

export default Banner;

const useStyles = makeStyles({
  block: {
    position: 'relative',
    padding: '16px',
    height: 'calc(100vh - 85px)',
    background: 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(images/banner.jpeg)',
    backgroundSize: 'cover',
    backgroundPosition: '40% 50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    '@media (min-width: 600px)': {
      padding: '32px'
    },
    '@media (min-width: 1240px)': {
      padding: '40px 32px'
    },
  },
  title: {
    color: '#fafafa',
    letterSpacing: '3px',
    textShadow: '0 0 3px #171f27',
    fontSize: '30px',
    textAlign: 'center',
    zIndex: 1,
    position: 'relative',
    '@media (min-width: 600px)': {
      fontSize: '50px'
    },
    '@media (min-width: 1240px)': {
      fontSize: '80px'
    },
  },
  subtitle: {
    color: '#fafafa',
    letterSpacing: '1px',
    fontSize: '14px',
    textAlign: 'center',
    zIndex: 1,
    position: 'relative',
    '@media (min-width: 600px)': {
      fontSize: '20px'
    },
    '@media (min-width: 1240px)': {
      fontSize: '24px'
    },
  },
  btnDown: {
    position: 'absolute',
    bottom: '5%',
    left: '50%',
  },
  btnDownIcon: {
    fontSize: '50px !important',
    color: '#fff',
  },
});
