import config from '../config';
// Types
import RequestMethods from 'types/RequestMethods';
import TokenTypes from 'types/TokenTypes';
// Models
import IHttpResponse from 'models/HttpResponse';
// Service
import StorageService from 'services/Storage.service';

const excludedUrls:string[] = ['/auth/login'];

const headers = {
  'Content-Type': 'application/json'
};

export default class HttpClient {
  public async get<T>(
    path:string,
    params: any = {},
    args:RequestInit = { method: RequestMethods.Get },
  ):Promise<IHttpResponse<T>> {
    const queryParams = new URLSearchParams();
    Object.keys(params).forEach((key:string) => {
      if ( Array.isArray(params[key]) && params[key].length < 1 ) return;      
      if ( (params[key] || typeof params[key] === 'boolean') && params[key] !== '' && params[key] !== ' ' && !params[key].id) queryParams.append(key, params[key]);
    });
    return await this._http<T>(`${path}?${queryParams}`, args);
  };

  public async post<T>(
    path:string,
    body?:any,
    args:RequestInit = {
      method: RequestMethods.Post,
      body: body instanceof FormData ? body : JSON.stringify(body)
    }
  ):Promise<IHttpResponse<T>> {
    if ( !(body instanceof FormData) ) args['headers'] = headers;
    return await this._http<T>(path, args);
  };

  public async put<T>(
    path:string,
    body:any,
    args:RequestInit = {
      method: RequestMethods.Put,
      body: body instanceof FormData ? body : JSON.stringify(body)
    }
  ):Promise<IHttpResponse<T>> {
    if ( !(body instanceof FormData) ) args['headers'] = headers;
    return await this._http<T>(path, args);
  };

  public async patch<T>(
    path:string,
    body:any,
    args:RequestInit = {
      method: RequestMethods.Patch,
      body: JSON.stringify(body)
    }
  ):Promise<IHttpResponse<T>> {
    args['headers'] = headers;
    return await this._http<T>(path, args);
  };

  public async delete<T>(
    path:string,
    args:RequestInit = { method: RequestMethods.Delete }
  ):Promise<IHttpResponse<T>> {
    return await this._http<T>(path, args);
  };

  private async _http<T>(path:string, args:RequestInit = {}):Promise<IHttpResponse<T>> {
    const accessToken:string | null = await this._getToken();

    if ( accessToken && !excludedUrls.includes(path) ){
      const tokenType:TokenTypes | string = StorageService.getTokenType();
      args.headers = {
        ...args.headers,
        Authorization: `${tokenType} ${accessToken}`
      }
    };

    const url:string = `${config.apiURL}${path}`;
    const request:RequestInfo = new Request(url, args);
    const response:IHttpResponse<T> = await fetch(request);
    try {
      response.parseBody = await response.json();
    } catch(error){}
    if ( !response.ok ) throw response.parseBody;
    return response;
  };

  private async _getToken():Promise<string | null> {
    const refreshToken:string = StorageService.getRefreshToken();
    if ( StorageService.isAccessTokenExpired() && refreshToken ){
      const response:Response = await fetch(`${config.apiURL}/auth/token`, {
        method: RequestMethods.Post,
        headers,
        body: JSON.stringify({ refreshToken })
      });
      const credential = await response.json();
      StorageService.setCredential(credential);
    }
    return StorageService.getAccessToken() || null;
  }
}
