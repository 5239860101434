import React, { useEffect } from 'react';
// Components
import HistoryList from 'pages/History/HistoryList';
// Utilities
import { scrollToTop } from 'utilities/Utilities';

const HistoryPage:React.FC = () => {
  
  useEffect(() => scrollToTop(), []);

  return (
    <React.Fragment>
      <HistoryList />
    </React.Fragment>
  );
}

export default HistoryPage;
