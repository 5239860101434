// Types
import TokenTypes from "types/TokenTypes";
// Models
import ICredential from "models/Credential";

const STORAGE_KEY:string = 'Trophy_Valley_Client';

export default class StorageService {
  private static readonly ACCESS_TOKEN:string = `${STORAGE_KEY}:access_token`;
  private static readonly REFRESH_TOKEN:string = `${STORAGE_KEY}:refresh_token`;
  private static readonly EXPIRES_IN:string = `${STORAGE_KEY}:expires_in`;
  private static readonly TOKEN_TYPE:string = `${STORAGE_KEY}:token_type`;

  public static getAccessToken():string {
    return localStorage.getItem(StorageService.ACCESS_TOKEN) || '';
  };
  public static getRefreshToken():string {
    return localStorage.getItem(StorageService.REFRESH_TOKEN) || '';
  };
  public static getExpiresIn():number {
    const expiresIn = localStorage.getItem(StorageService.EXPIRES_IN) || '0';
    return parseInt(expiresIn, 10);
  };
  public static getTokenType():TokenTypes | string {
    return localStorage.getItem(StorageService.TOKEN_TYPE) || '';
  };

  public static isAccessTokenExpired():boolean {
    if ( !StorageService.getAccessToken() ) return true;
    const currentTime = new Date().getTime();
    const expiresInTime = StorageService.getExpiresIn();
    return currentTime > expiresInTime;
  };

  public static setCredential(credential?:ICredential):void {
    if ( credential ){
      const { accessToken, refreshToken, expiresIn, tokenType } = credential;
      const currentDate = new Date();
      localStorage.setItem(StorageService.ACCESS_TOKEN, accessToken);
      localStorage.setItem(StorageService.REFRESH_TOKEN, refreshToken);
      localStorage.setItem(StorageService.EXPIRES_IN, currentDate.setSeconds(
        currentDate.getSeconds() + expiresIn
      ).toString());
      localStorage.setItem(StorageService.TOKEN_TYPE, tokenType);
    }
  };

  public static removeCredential():void {
    localStorage.removeItem(StorageService.ACCESS_TOKEN);
    localStorage.removeItem(StorageService.REFRESH_TOKEN);
    localStorage.removeItem(StorageService.EXPIRES_IN);
    localStorage.removeItem(StorageService.TOKEN_TYPE);
  };
}
