import { createAsyncThunk } from "@reduxjs/toolkit";
import IPrice from "models/Price";
import HttpClient from "utilities/HttpClient";

const url = '/prices';
const http = new HttpClient();

export const fetchPrices = createAsyncThunk('prices/fetchPrices', async(_:any , thunkApi) => {
  try {
    const { parseBody } = await http.get(url);
    return parseBody as IPrice[];
  } catch (e: any) {
    return thunkApi.rejectWithValue(e.response.data);
  }
});
