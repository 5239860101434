import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// Redux
import { setupStore } from 'store';
import { Provider } from 'react-redux';
// MUI
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';
import uk from 'dayjs/locale/uk';
import DayJsUtils from '@date-io/dayjs';
import { ukUA } from 'locales/ukUA';

import App from './App/App';
import theme from './theme';
import './index.scss';

const store = setupStore();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={5}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          utils={DayJsUtils}
          adapterLocale={uk}
          localeText={ukUA.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            <Provider store={store}>
              <App />
            </Provider>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </BrowserRouter>
);
