import React from 'react';
// hooks
import useDialog from 'hooks/useDialog';
import useScrollIntoView from 'hooks/useScrollIntoView';
// components
import Title from 'components/Title';
import Rules from 'components/Rules';
// mui
import { makeStyles } from '@mui/styles';
import { Box, Button, Typography } from '@mui/material';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate } from 'utilities/Framer';

const About:React.FC = () => {
  const classes = useStyles();
  const { scrollTo } = useScrollIntoView();
  const { Dialog, openDialog, closeDialog } = useDialog();
    
  return (
    <React.Fragment>
      <Dialog maxWidth="md">
        <Rules onClose={closeDialog} />
      </Dialog>

      <motion.div
        data-container="about"
        className={[classes.block, 'container'].join(' ')}
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.2 }}
      >
        <motion.div
          variants={bottomAnimate}
          custom={1}
        >
          <Title>Про водойму</Title>
        </motion.div>
        <Box className={classes.content}>
          <Box
            component="img"
            src="images/map.jpg" alt="карта озера"
            className={classes.img}
          />
          <Box className={classes.data}>
            <Typography className={classes.text}>
              Чому англійського стилю? <br /> Озеро не є великим, як і у Англії усі їхні озера, 100 метрів ширини, 354 довжини, тому має походження Англійського стилю.
            </Typography>
            <Typography className={classes.title}>Інформація:</Typography>
            <br />
            <Typography className={classes.text}>Площа водойми: 3.6 Га</Typography>
            <Typography className={classes.text}>Середня глибина: 2.0 м</Typography>
            <Typography className={classes.text}>Максимальна глибина: 2.8 м</Typography>
            <Typography className={classes.text}>Коли можна рибалити: Цілодобово</Typography>
            <br />
            <Typography className={classes.text}>Різновидність риб:</Typography>
            <Typography className={classes.text}>Короп, Короп Кої, Білий Амур, Чорний Амур, Карась, Плотва, Щука, Окунь, Йорж</Typography>
            <Box className={classes.buttons}>
              <Button
                variant="contained"
                onClick={() => scrollTo('reservation')}
              >
                Забронювати
              </Button>
              <Button
                variant="outlined"
                onClick={openDialog}
              >
                Правила
              </Button>
            </Box>
          </Box>
        </Box>
      </motion.div>
    </React.Fragment>
  );
}

export default About;

const useStyles = makeStyles({
  block: {
    paddingTop: '16px', 
    paddingBottom: '16px',
    '@media (min-width: 600px)': {
      paddingTop: '32px', 
      paddingBottom: '32px',

    },
    '@media (min-width: 900px)': {
      paddingTop: '40px', 
      paddingBottom: '40px',
    },
  },
  content: {
    paddingTop: '32px',
    maxWidth: 'max-content',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: '24px',
    '@media (min-width: 1240px)': {
      flexDirection: 'row',
      gap: '80px',
    },
  },
  img: {
    width: '100%',
    maxHeight: '500px',
    objectFit: 'contain',
    borderRadius: '16px',
    '@media (min-width: 1240px)': {
      width: '50%'
    }
  },
  data: {
    flexGrow: 1,
    color: '#fafafa',
  },
  buttons: {
    paddingTop: '40px',
    display: 'flex',
    gap: '16px',
  },
  title: {
    paddingTop: '16px',
    fontWeight: 500,
    fontSize: '16px',
    '@media (min-width: 1240px)': {
      fontSize: '24px',
    },
  },
  text: {
    paddingTop: '4px',
    fontSize: '13px',
    '@media (min-width: 1240px)': {
      fontSize: '16px'
    },
  },
});
