import { FC } from 'react';
// Mui
import { Typography } from '@mui/material';

type Props = {
  children: string;
}

const Title:FC<Props> = ({
  // Props
  children
}) => {
  return (
    <Typography
      sx={{
        color: '#fafafa',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '116.7%',
        letterSpacing: '-0.5px',
        '@media (min-width: 600px)': {
          fontSize: '24px',
        },
        '@media (min-width: 1240px)': {
          fontSize: '40px',
        },
      }}
    >
      {children}
    </Typography>
  )
}

export default Title;
