export const isRequired = {
  value: true,
  message: "Це поле є обов'язковим"
};

export const isEmail = {
  // eslint-disable-next-line
  value: /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i,
  message: 'Не коректний e-mail'
};

export const isMatch = (value:string | undefined, matchField:string | undefined, message = 'Поля не співпадають') => {
  if ( !value || !matchField ) return true;
  return value === matchField || message;
}

export const isMinValue = (value:string | undefined, min:number) => {
  if ( !value ) return true;
  return value.length >= min || `Повинно бути ${min} або більше символів`;
};

export const isLowerCase = (value:string | undefined) => {
  if ( !value ) return true;
  return /[a-z]/.test(value) || 'Повинно бути 1 або більше малих літер';
};

export const isUpperCase = (value:string | undefined) => {
  if ( !value ) return true;
  return /[A-Z]/.test(value) || 'Повинно бути 1 або більше великих літер';
};

export const isSpecial = (value:string | undefined) => {
  if ( !value ) return true;
  // eslint-disable-next-line
  return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value) || 'Повинно бути 1 або більше \'спеціальних\' символів';
};
