import { FC } from 'react';
// Mui
import { makeStyles } from '@mui/styles';
// Framer
import { motion } from 'framer-motion';
import ICompetition from 'models/Competition';
import { formatStartEndDate } from 'utilities/DateTimeFormatter';
import dayjs from 'dayjs';

const animate = {
  hidden: {
    opacity: 0,
    y: -100
  },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.4, delay: custom * 0.4 }, 
  })
}

type Props = {
  custom: number;
  competition: ICompetition;
};

const CalendarItem:FC<Props> = ({ custom, competition }) => {
  const classes = useStyles();

  return (
    <motion.div
      className={classes.item}
      variants={animate}
      custom={custom}
    >
      <div className={classes.date}>
        <h4 className={classes.title}>{dayjs(competition.startDate).format('MMM')}</h4>
        <h4 className={classes.title}>{dayjs(competition.startDate).format('D')}</h4>
      </div>
      <div className={classes.info}>
        <h4 className={classes.title}>{competition.name}</h4>
        <p className={classes.text}>Трофейна долина</p>
        <p className={classes.text}>{formatStartEndDate(competition.startDate, competition.endDate)}</p>
      </div>
    </motion.div>
  );
}

export default CalendarItem;

const useStyles = makeStyles({
  item: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
  },
  date: {
    minWidth: '60px',
    maxWidth: '60px',
    height: '80px',
    backgroundColor: '#222',
    borderRadius: '4px 0 0 4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    fontSize: '18px',
    '@media (min-width: 600px)': {
      minWidth: '80px',
      maxWidth: '80px',
    }
  },
  title: {
    fontSize: '14px',
    '@media (min-width: 600px)': {
      fontSize: '18px',
    }
  },
  info: {
    padding: '5px 10px',
    '@media (min-width: 600px)': {
      padding: '5px 20px',
    }
  },
  text: {
    fontSize: '12px',
    paddingTop: '4px',
    '@media (min-width: 600px)': {
      fontSize: '14px',
    }
  },
});
