import { createAsyncThunk } from "@reduxjs/toolkit";
import ISponsor from "models/Sponsor";
import HttpClient from "utilities/HttpClient";

const url = '/sponsors';
const http = new HttpClient();

export const fetchSponsors = createAsyncThunk('sponsors/fetchSponsors', async(_:any , thunkApi) => {
  try {
    const { parseBody } = await http.get(url);
    return parseBody as ISponsor[];
  } catch (e: any) {
    return thunkApi.rejectWithValue(e.response.data);
  }
});
