import { FC, Fragment, useEffect } from 'react';
// mui
import { IconButton, DialogTitle, DialogContent } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useAppDispatch } from 'hooks/redux';
import TemplatesAsync from 'store/templates/templatesAsync';
import { useSelector } from 'react-redux';
import { selectTemplate } from 'store/templates/templatesSelectors';
import { templatesActions } from 'store/templates/templatesSlice';

type Props = {
  onClose: () => void;
}

const Rules:FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const template = useSelector(selectTemplate);

  useEffect(() => {
    dispatch(TemplatesAsync.fetchTemplate('rules'));

    return () => {
      dispatch(templatesActions.setInitialField('template'));
    }
    // eslint-disable-next-line
  }, []);

  if (!template) return null;
  return (
    <Fragment>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {template.title}
        <IconButton
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <iframe
          title={template.slug}
          srcDoc={template.body}
          style={{ border: 'none', width: '100%', height: '70vh' }}
        />
      </DialogContent>
    </Fragment>
  )
}

export default Rules;
