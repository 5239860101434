import { createAsyncThunk } from "@reduxjs/toolkit";
import { AssetsActions } from "./assetsSlice";
import HttpClient from 'utilities/HttpClient';

const url:string = '/assets';
const http = new HttpClient();

const AssetsAsync = {
  createAsset: createAsyncThunk('assets/createAsset', async (assetData:{file: File}, thunkApi) => {
    const { file } = assetData;

    const formData = new FormData();
    formData.append('file', file, file.name);

    const {parseBody} = await http.post(url, formData);
    thunkApi.dispatch(AssetsActions.setInitialField('file'));
    return parseBody;
  }),
}

export default AssetsAsync;
