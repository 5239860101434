import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
// Hooks
import { useAppDispatch } from 'hooks/redux';
// Async
import ReservationsRequestsAsync from 'store/reservationsRequests/reservationsRequestsAsync';
// Models
import IReservationRequest from "models/ReservationRequest";
// Selectors
import { selectReservationsRequests, selectParams, selectTotal } from "store/reservationsRequests/reservationsRequestsSelectors";
// Components
import ReservationRequestItem from "./ReservationRequestItem";
import Title from 'components/Title';
import Loader from 'components/Loader';
// mui
import { Box, Tab, TablePagination, Tabs, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';

const ReservationsPage:React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const reservationsRequests = useSelector(selectReservationsRequests);
  const total = useSelector(selectTotal);
  const params = useSelector(selectParams);

  const [tab, setTab] = useState<'current' | 'past'>('current');
  const handleChangeTab = (_: any, newTab: 'current' | 'past') => setTab(newTab);

  const handleChangePage = (_:any, page: number) => {
    dispatch(ReservationsRequestsAsync.fetchReservationsRequests({ ...params, page: page + 1 }));
  }

  const handleChangeLimit = (e:any) => {
    const { value: limit } = e.target;
    dispatch(ReservationsRequestsAsync.fetchReservationsRequests({ ...params, page: 1, limit, }));
  }

  useEffect(() => {
    const nextParams:any = {...params};
    const today = dayjs().format('YYYY-MM-DD');
    dispatch(ReservationsRequestsAsync.fetchReservationsRequests({...nextParams, startDate: tab === 'current' ? today : '', endDate: tab !== 'current' ? today : ''}));
    // eslint-disable-next-line
  }, [tab]);

  if (!reservationsRequests) return <Loader />;
  return (
    <section className={`${classes.block} container`}>
      <Title>Бронювання</Title>
      <Box sx={{ pt: 2, borderBottom: 1, borderColor: 'divider', maxWidth: 'max-content' }}>
        <Tabs
          variant="scrollable"
          value={tab}
          onChange={handleChangeTab}
          aria-label="Reservations tabs"
        >
          <Tab sx={{ fontSize: { xs: '12px', sm: '14px', color: 'rgba(255,255,255,0.6)' } }} value="current" label="Майбутні" />
          <Tab sx={{ fontSize: { xs: '12px', sm: '14px', color: 'rgba(255,255,255,0.6)' } }} value="past" label="Попередні" />
        </Tabs>
      </Box>
      {!!reservationsRequests.length ? (
        <Box className={classes.list} sx={{ mt: 3 }}>
          <Box className={classes.items}>
            {reservationsRequests?.map((reservationRequest: IReservationRequest) => (
              <ReservationRequestItem key={reservationRequest._id} reservationRequest={reservationRequest} tab={tab} />
            ))}
          </Box>
          <Box>
            <TablePagination
              className={classes.pagination}
              component="div"
              count={total}
              page={params.page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={params.limit}
              onRowsPerPageChange={handleChangeLimit}
              labelRowsPerPage="Показувати"
              labelDisplayedRows={({from, to, count}) => `${from}–${to} з ${count}`}
              rowsPerPageOptions={[20, 50, 100]}
            />
          </Box>
        </Box>
      ) : (
        <Typography sx={{ color: '#eee', pt: 4, textAlign: 'center' }}>
          {`${tab === 'current' ? 'Майбутніх' : 'Попередніх'} бронювань немає`}
        </Typography>
      )}
    </section>
  );
}

export default ReservationsPage;

const useStyles = makeStyles({
  block: {
    paddingTop: '16px',
    paddingBottom: '16px',
    '@media (min-width: 600px)': {
      paddingTop: '32px',
      paddingBottom: '32px',
    },
    '@media (min-width: 1200px)': {
      paddingTop: '40px',
      paddingBottom: '40px',
    },
  },
  list: {
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    overflow: 'hidden',
  },
  items: {
    maxHeight: 'calc(100vh - 375px)',
    overflowY: 'scroll',
    '@media (min-width: 600px)': {
      maxHeight: 'calc(100vh - 395px)',
    },
    '@media (min-width: 1200px)': {
      maxHeight: 'calc(100vh - 429px)',
    },
  },
  pagination: {
    boxShadow: '0px -3px 6px -1px rgb(0 0 0 / 8%)',
    color: '#fefefe !important',
  },
});
