import React, { useEffect, useState } from 'react';
// Components
import Title from 'components/Title';
// MUI
import { makeStyles } from '@mui/styles';
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper";
// Styles
import './Gallery.css';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate } from 'utilities/Framer';

const Gallery:React.FC = () => {
  const classes = useStyles();

  const [centeredSlides, setCenteredSlides] = useState(window.innerWidth >= 1240 ? true : false);

  const handleCenteredSlides = () => {
    setCenteredSlides(window.innerWidth >= 1240 ? true : false);
  }

  useEffect(() => {
    window.addEventListener('resize', handleCenteredSlides);

    return () => {
      window.removeEventListener('resize', handleCenteredSlides);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <motion.section
      data-container="gallery"
      className={[classes.block, 'container'].join(' ')}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <motion.div
        variants={bottomAnimate}
        custom={1}
      >
        <Title>Галарея</Title>
      </motion.div>
      <h3 className={classes.subtitle}>Наше озеро</h3>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={centeredSlides}
        slidesPerView={centeredSlides ? 2 : 1.2}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
          
        }}
        pagination={{
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        {new Array(21).fill('').map((_, index) => (
          <SwiperSlide key={`lake-image-${index}`}>
            <img src={`images/gallery/lake/${index+1}.jpeg`} alt="" className={classes.image} />
          </SwiperSlide>
        ))}
      </Swiper>

      <h3 className={classes.subtitle}>Наші трофеї</h3>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={centeredSlides}
        slidesPerView={centeredSlides ? 2 : 1.2}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        {new Array(39).fill('').map((_, index) => (
          <SwiperSlide key={`fish-image-${index}`}>
            <img src={`images/gallery/fish/${index+1}.jpeg`} alt="" className={classes.image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </motion.section>
  );
}

export default Gallery;

const useStyles = makeStyles({
  block: {
    paddingTop: '16px', 
    paddingBottom: '16px',
    '@media (min-width: 600px)': {
      paddingTop: '32px', 
      paddingBottom: '32px',

    },
    '@media (min-width: 900px)': {
      paddingTop: '40px', 
      paddingBottom: '40px',
    },
  },
  subtitle: {
    paddintTop: '32px',
    fontSize: '16px',
    textAlign: 'center',
    color: '#fff',
    '@media (min-width:600px)': {
      fontSize: '20px'
    },
    '@media (min-width:1240px)': {
      fontSize: '24px'
    },
  },
  image: {
    maxHeight: '250px',
    objectFit: 'contain',
    '@media (min-width: 900px)': {
      maxHeight: '500px',
    },
  },
});
