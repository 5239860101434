import { FC, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Hooks
import useMenu from 'hooks/useMenu';
import { useAppDispatch } from 'hooks/redux';
// Async
import AuthAsync from 'store/auth/authAsync';
// Selectors
import { selectCurrentUser } from 'store/users/usersSelectors';
// Mui
import { Divider, Avatar, Chip } from '@mui/material';
import { ArrowDropDown as ArrowDropdownIcon } from '@mui/icons-material';

const UserMenu:FC = () => {
  const dispatch = useAppDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const signOut = () => {
    dispatch(AuthAsync.signOut());
  }

  const { Menu, MenuItem, openMenu } = useMenu();

  if ( !currentUser ) return null;
  return (
    <Fragment>
      <Chip
        variant="outlined"
        color="primary"
        avatar={(
          <Avatar
            src={currentUser.image ? currentUser.image.path : ''}
          >
            {currentUser.image ? '' : currentUser.firstName[0]}
          </Avatar>
        )}
        label={currentUser.firstName}
        deleteIcon={<ArrowDropdownIcon />}
        onClick={openMenu}
        onDelete={openMenu}
      />
      <Menu>
        <MenuItem component={NavLink} to="/profile">
          Профіль
        </MenuItem>
        <MenuItem component={NavLink} to="/reservations">
          Бронювання
        </MenuItem>
        <Divider />
        <MenuItem onClick={signOut}>
          Вийти
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default UserMenu;
