import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Async
import TemplatesAsync from "./templatesAsync";
// Models
import ITemplate from "models/Template";

interface IState {
  template: ITemplate | null;
}

const initialState: IState = {
  template: null,
}

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch templates
      .addCase(TemplatesAsync.fetchTemplate.fulfilled, (state, action:PayloadAction<ITemplate>) => {
        state.template = action.payload;
      })
  }
})

export const templatesActions = templatesSlice.actions;
export default templatesSlice.reducer;
