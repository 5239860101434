import React, { useEffect } from 'react';
// Components
import CalendarList from './CalendarList';
// Utilities
import { scrollToTop } from 'utilities/Utilities';

const CalendarPage:React.FC = () => {
  
  useEffect(() => scrollToTop(), []);

  return (
    <React.Fragment>
      <CalendarList />
    </React.Fragment>
  );
}

export default CalendarPage;
