import { createAsyncThunk } from "@reduxjs/toolkit";
// Models
import { RootState } from "store";
import IUser from "models/User";
// Types
import NotificationStatuses from "types/NotificationStatuses";
// Async
import AssetsAsync from "store/assets/assetsAsync";
// Actions
import { authActions } from "store/auth/authSlice";
import { appActions } from "store/app/appSlice";
// Selectors
import { selectFile } from "store/assets/assetsSelectors";
// Utilities
import HttpClient from "utilities/HttpClient";

const url = '/users';
const http = new HttpClient();

const UsersAsync = {
  fetchMe: createAsyncThunk('users/fetchMe', async (_, thunkApi) => {
    const { parseBody } = await http.get(`${url}/me`);
    thunkApi.dispatch(authActions.setAuthenticated(true));
    return parseBody as IUser;
  }),
  updateUser: createAsyncThunk('users/updateUser', async (data: any, thunkApi) => {
    const nextData = {...data};
    // Update assets
    const state = thunkApi.getState() as RootState;
    const file = selectFile(state);

    if (file) {
      const { payload }: any = await thunkApi.dispatch(AssetsAsync.createAsset({ file }));
      console.log(payload);
      nextData['imageId'] = payload._id;
    }

    console.log(nextData);
    

    const { parseBody } = await http.put(`${url}/me`, nextData);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Профіль успішно оновлено', options: { variant: NotificationStatuses.Success } }));
    return parseBody as IUser;
  }),
}

export default UsersAsync;
