// Mui
import { createTheme } from '@mui/material/styles';
import { ukUA } from 'locales/ukUA';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif',
      '-apple-system'
    ].join(',')
  },
  palette: {
    primary: {
      main: '#CFB07A',
    },
    secondary: {
      main: '#262E36',
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(255,255,255,0.6)'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontWeight: 500,
          fontSize: '17px',
          lineHeight: '26px',
          letterSpacing: '0.1px',
          transition: 'ease-in-out .3s',
        },
        contained: {
          backgroundColor: '#CFB07A',
          '&:hover': {
            backgroundColor: '#f0cb8a',
          }
        },
        outlined: {
          color: '#CFB07A',
          border: '1px solid #CFB07A',
          '&:hover': {
            backgroundColor: 'rgba(#CFB07A, 0.1)',
          }
        },
        containedSecondary: {
          backgroundColor: '#fff',
          color: '#000',
          '&:hover': {
            backgroundColor: '#f0cb8a',
          }
        },
        outlinedSecondary: {
          color: '#fff',
          border: '1px solid #fff',
        },
        textSecondary: {
          color: '#fff',
          border: '1px solid transparent',
          '&:hover': {
            border: '1px solid rgba(255,255,255,0.2)',
          }
        }
      }
    }
  }
}, ukUA);

export default theme;
