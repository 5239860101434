import { createAsyncThunk } from "@reduxjs/toolkit";
import ITemplate from "models/Template";
import HttpClient from "utilities/HttpClient";

const url = '/templates';
const http = new HttpClient();

const TemplatesAsync = {
  fetchTemplate: createAsyncThunk('templates/fetchTemplate', async (slug: string) => {
    const { parseBody } = await http.get(`${url}/${slug}`);
    return parseBody as ITemplate;
  }),
}

export default TemplatesAsync;
