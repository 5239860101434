import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
// Hooks
import { useAppDispatch } from 'hooks/redux';
// Async
import UsersAsync from 'store/users/usersAsync';
// Selectors
import { selectCurrentUser, selectRemoveImage } from 'store/users/usersSelectors';
// Components
import Title from 'components/Title';
import Phone from 'components/Phone';
import StyledInput from 'components/StyledInput';
import ImageUploader from './ImageUploader';
// MUI
import { LoadingButton } from '@mui/lab';
import {
  Box, Button, FormGroup, FormHelperText, Grid,
  IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import {
  Circle as CircleIcon,
  CancelOutlined as CancelOutlinedIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
// utilites
import {
  isEmail, isRequired, isMinValue, isUpperCase, isSpecial, isLowerCase, isMatch
} from 'utilities/Validation';
import { scrollToTop } from 'utilities/Utilities';

interface IForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  carNumbers?: any[];
  team?: string;
  password?: string;
  rePassword?: string;
}

const ProfilePage: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const removeImage = useSelector(selectRemoveImage);

  const { handleSubmit, control, register, formState: {errors}, reset, watch } = useForm<IForm>({
    defaultValues: {
      email: currentUser?.email,
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      phone: currentUser?.phone,
      carNumbers: currentUser?.carNumbers ? currentUser.carNumbers.map(number => ({ value: number })) : [],
      team: currentUser?.team || '',
      password: '',
      rePassword: '',
    }
  });

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: "carNumbers",
  });

  const handleAddCarNumber = () => {
    append({ value: '' });
  }

  useEffect(() => {
    reset({
      email: currentUser?.email,
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      phone: currentUser?.phone,
      carNumbers: currentUser?.carNumbers ? currentUser.carNumbers.map(number => ({ value: number })) : [],
      team: currentUser?.team || '',
      password: '',
      rePassword: '',
    });
    // eslint-disable-next-line
  }, [currentUser])

  const onSubmit = handleSubmit((data: IForm) => {
    const { password, rePassword, carNumbers, ...nextData } = data;
    const newData: any = {
      ...nextData,
      role: 'client',
      imageId: currentUser?.image && !removeImage ? currentUser.image._id : null,
    };

    if (password) newData['password'] = password;
    if (carNumbers?.length) newData['carNumbers'] = carNumbers?.map((number: any) => number.value);

    setIsLoading(true);
    dispatch(UsersAsync.updateUser(newData))
      .unwrap()
      .finally(() => setIsLoading(false))
  });

  const watchPassword = watch('password');
  const watchRePassword = watch('rePassword');

  useEffect(() => scrollToTop(), []);

  if (!currentUser) return null;
  return (
    <Paper variant="outlined" sx={{ backgroundColor: 'transparent', borderColor: '#fff', maxWidth: '600px', p: { xs: 2, md: 5 }, margin: { xs: '80px 16px 16px', sm: '80px auto 0' } }}>
      <Title>Профіль</Title>
      <form onSubmit={onSubmit} noValidate>
          <Grid container spacing={2} sx={{ pt: 4, pb: 4 }}>
            <Grid item xs={12}>
              <ImageUploader image={currentUser.image} />
            </Grid>
            {/* email */}
            <Grid item xs={12}>
              <Controller
                control={control} name="email"
                rules={{ required: isRequired, pattern: isEmail }}
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    label="E-mail"
                    fullWidth
                    required
                    error={!!errors?.email}
                    helperText={errors?.email ? errors.email.message : null}
                    InputProps={{
                      style: {
                        color: '#fefefe'
                      }
                    }}
                  />
                )}
              />
            </Grid>
            {/* firstName */}
            <Grid item xs={12} md={6}>
              <Controller
                control={control} name="firstName"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    label="Ім'я"
                    fullWidth
                    required
                    error={!!errors?.firstName}
                    helperText={errors?.firstName ? errors.firstName.message : null}
                    InputProps={{
                      style: {
                        color: '#fefefe'
                      }
                    }}
                  />
                )}
              />
            </Grid>
            {/* lastName */}
            <Grid item xs={12} md={6}>
              <Controller
                control={control} name="lastName"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    label="Прізвище"
                    fullWidth
                    required
                    error={!!errors?.lastName}
                    helperText={errors?.lastName ? errors.lastName.message : null}
                    InputProps={{
                      style: {
                        color: '#fefefe'
                      }
                    }}
                  />
                )}
              />
            </Grid>
            {/* phone */}
            <Grid item xs={12}>
              <Controller
                control={control} name="phone"
                rules={{ required: isRequired }}
                render={({ field: { onChange, value } }) => (
                  <Phone
                    value={value || ''}
                    onChange={onChange}
                    label="Телефон"
                    required
                    error={!!errors?.phone}
                    helperText={errors?.phone ? errors.phone.message : null}
                  />
                )}
              />
            </Grid>
            {/* firstName */}
            <Grid item xs={12}>
              <Controller
                control={control} name="team"
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    label="Команда"
                    fullWidth
                    InputProps={{
                      style: {
                        color: '#fefefe'
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 0.5 }}>
                <Typography sx={{ fontSize: '18px', color: '#fefefe' }}>{`Номер(а) машини`}</Typography>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleAddCarNumber}
                >
                  Додати
                </Button>
              </Box>
            </Grid>
            {/* carNumbers */}
            {fields.map((field, index) => (
              <Grid item xs={12} key={field.id}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <StyledInput
                    {...register(`carNumbers.${index}.value`, { required: isRequired })}
                    fullWidth
                    label={`Номерний знак ${index + 1}`}
                    required
                    error={errors?.carNumbers ? !!errors?.carNumbers[index] : false}
                    helperText={errors?.carNumbers ? (errors?.carNumbers[index] as any)?.value?.message : ''}
                    InputProps={{
                      style: {
                        color: '#fefefe'
                      }
                    }}
                  />
                  <Tooltip title="Видалити">
                    <IconButton onClick={() => remove(index)}>
                      <DeleteIcon sx={{ color: '#fefefe' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Controller
                control={control} name="password"
                rules={{
                  required: {
                    ...isRequired,
                    value: watchRePassword !== ''
                  },
                  validate: {
                    isMinValue: (value:string | undefined) => isMinValue(value, 8),
                    isLowerCase,
                    isUpperCase,
                    isSpecial
                  }
                }}
                render={({ field }) => (
                  <StyledInput
                    { ...field }
                    fullWidth
                    label="Новий пароль"
                    type="password"
                    autoComplete="off"
                    required={watchRePassword !== ''}
                    error={Boolean(errors.password && watchRePassword !== '')}
                    helperText={errors.password && watchRePassword !== '' && errors.password.type === 'required' ? errors.password.message : ''}
                    InputProps={{
                      style: {
                        color: '#fefefe'
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control} name="rePassword"
                rules={{
                  required: {
                    ...isRequired,
                    value: watchPassword !== ''
                  },
                  validate: {
                    isPasswordMatch: (value:string | undefined) => isMatch(value, watchPassword, 'Пароль не співпадає')
                  }
                }}
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    fullWidth
                    label="Підтвердити пароль"
                    type="password"
                    autoComplete="off"
                    required={watchPassword !== ''}
                    error={Boolean(errors.rePassword && watchPassword !== '')}
                    helperText={errors.rePassword && watchPassword !== '' ? errors.rePassword.message : ''}
                    InputProps={{
                      style: {
                        color: '#fefefe'
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormHelperText sx={{
                  display: 'flex', gap: 0.5, alignItems: 'center',
                  color: !watchPassword ? 'gray' : isMinValue(watchPassword, 8) === true ? '#2E7D32' : '#C62828'
                }}>
                  
                  {!watchPassword ? <CircleIcon sx={{ fontSize: '10px' }} /> : isMinValue(watchPassword, 8) === true ? <CheckCircleOutlinedIcon /> : <CancelOutlinedIcon /> }
                  Ваш пароль має містити 8 або більше символів
                </FormHelperText>
                <FormHelperText sx={{
                  display: 'flex', gap: 0.5, alignItems: 'center',
                  color: !watchPassword ? 'gray' : isUpperCase(watchPassword) === true && isLowerCase(watchPassword) === true ? '#2E7D32' : '#C62828'
                }}>
                  {!watchPassword ? <CircleIcon sx={{ fontSize: '10px' }} /> : isUpperCase(watchPassword) === true && isLowerCase(watchPassword) === true ? <CheckCircleOutlinedIcon /> : <CancelOutlinedIcon /> }
                  Ваш пароль має містити 1 або більше великих та малих літер.
                </FormHelperText>
                <FormHelperText sx={{
                  display: 'flex', gap: 0.5, alignItems: 'center',
                  color: !watchPassword ? 'gray' : isSpecial(watchPassword) === true ? '#2E7D32' : '#C62828'
                }}>
                  {!watchPassword ? <CircleIcon sx={{ fontSize: '10px' }} /> : isSpecial(watchPassword) === true ? <CheckCircleOutlinedIcon /> : <CancelOutlinedIcon /> }
                  Ваш пароль має містити 1 або більше спеціальних симолів.
                </FormHelperText>
              </FormGroup>
            </Grid>
          </Grid>
          <LoadingButton
            fullWidth
            loading={isLoading}
            type='submit'
            variant='contained'
          >
            Зберегти
          </LoadingButton>
        </form>
    </Paper>
  );
};

export default ProfilePage;
