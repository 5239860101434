import React from 'react';
import config from 'config';
import axios from 'axios';
import dayjs from 'dayjs';
// Hooks
import { useAppDispatch } from 'hooks/redux';
import useToggle from 'hooks/useToggle';
import useMenu from 'hooks/useMenu';
// Async
import ReservationsRequestsAsync from 'store/reservationsRequests/reservationsRequestsAsync';
// Models
import IReservationRequest from 'models/ReservationRequest';
// Types
import ReservationRequestStatuses from 'types/ReservationRequestStatuses';
// MUI
import { makeStyles } from '@mui/styles';
import {
  Box, Card, Chip, Collapse, Divider, Grid,
  IconButton, Tooltip, Typography
} from '@mui/material';
import {
  DoneAll, Close, AccessTimeOutlined,
  ReportOutlined, MoreVert
} from '@mui/icons-material';
// Components
import Collapsable from 'components/Collapsable';
import ConfirmCancelReservationRequest from './ConfirmCancelReservationRequest';
// Utilities
import { formatStartEndDate } from 'utilities/DateTimeFormatter';
import { formatPhone } from 'utilities/StringFromatter';

const { token, chatId } = config;

type Props = {
  reservationRequest: IReservationRequest;
  tab: 'current' | 'past';
};

const format = 'DD.MM.YYYY';

const ReservationRequestItem:React.FC<Props> = ({ reservationRequest, tab }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open, toggle } = useToggle();
  const { open:extended, toggle:toggleExtended } = useToggle();

  const handleCancelReservationRequest = () => {
    dispatch(ReservationsRequestsAsync.cancelReservationRequest(reservationRequest._id)).unwrap()
      .then(() => {
        const text = `❌ ${reservationRequest.firstName} ${reservationRequest.lastName} скасував запит на бронювання з ${dayjs(reservationRequest.startDate).format(format)} по ${dayjs(reservationRequest.endDate).format(format)}. \nСектор(и): ${reservationRequest.sectors.join(', ')}`;
        axios.get(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&parse_mode=HTML&text=${encodeURIComponent(text)}`);
        toggle();
      })
  }

  const isVisibleMenu = [ReservationRequestStatuses.Pending, ReservationRequestStatuses.Submitted].includes(reservationRequest.status) && tab === 'current';

  return (
    <React.Fragment>
      <ConfirmCancelReservationRequest open={open} onClose={toggle} onSubmit={handleCancelReservationRequest} />

      <Card sx={{ p: 2, borderRadius: 0, backgroundColor: extended ? '#262E36' : 'transparent', borderBottom: '1px solid #eee', width: '100%' }}>
        <Collapsable onClick={toggleExtended}>
        <Grid container spacing={2}>
            <Grid item xs={10} md={3} alignSelf="center" sx={{ order: -1 }}>
              <Typography className={classes.title}>Ім'я:</Typography>
              <Typography className={classes.text}>
                {`${reservationRequest.firstName} ${reservationRequest.lastName}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2} alignSelf="center">
              <Typography className={classes.title}>Телефон:</Typography>
              <Typography className={classes.text}>
                {formatPhone(reservationRequest.phone)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className={classes.title} sx={{ display: {xs: 'block', sm: 'none'} }}>Дата:</Typography>
                <Typography className={classes.text}>
                  {formatStartEndDate(reservationRequest.startDate, reservationRequest.endDate)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: {xs: 2, sm: 0} }}>
                <Typography className={classes.title} sx={{ display: {xs: 'block', sm: 'none'} }}>Статус:</Typography>
                <Box>
                  {reservationRequest.status === ReservationRequestStatuses.Submitted && <Chip color={'success'} size="small" label="Підтверджено" icon={<DoneAll />} />}
                  {reservationRequest.status === ReservationRequestStatuses.Pending && <Chip color={'info'} size="small" label="Очікується" icon={<AccessTimeOutlined />} />}
                  {reservationRequest.status === ReservationRequestStatuses.Cancelled && <Chip color={'warning'} size="small" label="Скасовано" icon={<Close />} />}
                  {reservationRequest.status === ReservationRequestStatuses.Rejected && <Chip color={'error'} size="small" label="Відхилено" icon={<ReportOutlined />} />}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={2} alignSelf="center">
              <Typography className={classes.title}>Номер авто:</Typography>
              <Typography className={classes.text}>
                {reservationRequest.carNumber || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={1} alignSelf="center">
              <Typography className={classes.title}>Сектор(и):</Typography>
              <Typography className={classes.text}>
                {reservationRequest.sectors.join(', ')}
              </Typography>
            </Grid>
            {isVisibleMenu && (
              <Grid item xs={2} md={1} alignSelf="center" sx={{ order: { xs: -1, md: 0 } }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Tooltip title="Меню">
                    <IconButton aria-label="Меню" onClick={openMenu}>
                      <MoreVert sx={{ color: '#fefefe' }} />
                    </IconButton>
                  </Tooltip>
                  <Menu>
                    <MenuItem onClick={toggle} sx={{ color: '#D32F2F' }}>Скасувати</MenuItem>
                  </Menu>
                </Box>
              </Grid>
            )}
          </Grid>
        </Collapsable>
        <Collapse in={extended}>
          <Divider sx={{ p: 1 }} />
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid item xs={12}>
              <Typography className={classes.title}>
                Повідомлення:
              </Typography>
              <Typography className={classes.text}>
                {reservationRequest.notes || '-'}
              </Typography>
            </Grid>
          </Grid>
        </Collapse>
      </Card>
    </React.Fragment>
  );
}

export default ReservationRequestItem;

const useStyles = makeStyles({
  title: {
    fontSize: '12px',
    lineHeight: '166%',
    letterSpacing: '0.4px',
    color: 'rgba(255, 255, 255, 0.6)',
  },
  text: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: 'rgba(255, 255, 255, 0.87)',
  },
});
