export const bottomAnimate = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.4, delay: custom * 0.4 }, 
  })
}

export const opacityAnimate = {
  hidden: {
    opacity: 0,
  },
  visible: (custom: number) => ({
    opacity: 1,
    transition: { duration: 0.4, delay: custom * 0.4 }, 
  })
}

export const leftAnimate = {
  hidden: {
    opacity: 0,
    x: -100
  },
  visible: (custom: number) => ({
    opacity: 1,
    x: 0,
    transition: { duration: 0.4, delay: custom * 0.4 }, 
  })
}

export const rightAnimate = {
  hidden: {
    opacity: 0,
    x: 100
  },
  visible: (custom: number) => ({
    opacity: 1,
    x: 0,
    transition: { duration: 0.4, delay: custom * 0.4 }, 
  })
}

export const topAnimate = {
  hidden: {
    opacity: 0,
    y: -100,
  },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.4, delay: custom * 0.4 }, 
  })
}
