import React from 'react';
import { makeStyles } from '@mui/styles';

const Loader:React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.loaderWrapper}>
      <div>
        <div className={classes.ldsRipple}>
          <div className={classes.ldsRippleItem}></div>
          <div className={[classes.ldsRippleItem, classes.ldsRippleItem2].join(' ')}></div>
        </div>
      </div>
    </div>
  );
}

export default Loader;

const useStyles = makeStyles({
  loaderWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(90deg, #0c141a 0%, #1d262d 100%)',
  },
  ldsRipple: {
    display: 'inline-block',
    position: 'relative',
    width: '80px',
    height: '80px',
  },
  ldsRippleItem: {
    position: 'absolute',
    border: '4px solid #CFB07A',
    opacity: 1,
    borderRadius: '50%',
    animation: '$lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite',
  },
  ldsRippleItem2: {
    animationDelay: '-0.5s',
  },
  '@keyframes lds-ripple': {
    '0%': {
      top: '36px',
      left: '36px',
      width: 0,
      height: 0,
      opacity: 1,
    },
    '100%': {
      top: 0,
      left: 0,
      width: '72px',
      height: '72px',
      opacity: 0,
    }
  }
});
