import { FC, ReactNode } from 'react';
// Mui
import { makeStyles } from '@mui/styles';
// Framer
import { motion } from 'framer-motion';

const leftAnimate = {
  hidden: {
    opacity: 0,
    x: -100
  },
  visible: (custom: number) => ({
    opacity: 1,
    x: 0,
    transition: { duration: 0.4, delay: custom * 0.4 }, 
  })
}

const rightAnimate = {
  hidden: {
    opacity: 0,
    x: 100
  },
  visible: (custom: number) => ({
    opacity: 1,
    x: 0,
    transition: { duration: 0.4, delay: custom * 0.4 }, 
  })
}

type Props = {
  direction: 'right' | 'left';
  custom: number;
  children: ReactNode;
};

const HistoryItem:FC<Props> = ({ direction, custom, children }) => {
  const classes = useStyles();

  return (
    <motion.div
      className={classes.item}
      variants={direction === 'left' ? leftAnimate : rightAnimate}
      custom={custom}
    >
      <p
        className={classes.text}
      >
        {children}
      </p>
    </motion.div>
  );
}

export default HistoryItem;

const useStyles = makeStyles({
  item: {
    padding: '12px 22px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '8px',
  },
  text: {
    fontSize: '13px',
    '@media (min-width: 1240px)': {
      fontSize: '16px',
    },
  },
});
