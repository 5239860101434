import { FC, useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { useSelector } from 'react-redux';
// Async
import TemplatesAsync from 'store/templates/templatesAsync';
// Actions
import { templatesActions } from 'store/templates/templatesSlice';
// Selectors
import { selectTemplate } from 'store/templates/templatesSelectors';
// Components
import Title from 'components/Title';
import HistoryItem from './HistoryItem';
import Loader from 'components/Loader';
// Mui
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
// Framer
import { motion } from 'framer-motion';


const HistoryList:FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const template = useSelector(selectTemplate);

  const mapBodyTemplate = (content: string) => {
    return content.split('<p>')
      .map((row) => row.replace('</p>', '').replace('&nbsp;', ''))
      .filter(Boolean);
  }

  useEffect(() => {
    dispatch(TemplatesAsync.fetchTemplate('history'));

    return () => {
      dispatch(templatesActions.setInitialField('template'));
    }
    // eslint-disable-next-line
  }, []);

  if (!template) return <Loader />;
  return (
    <motion.section
      className={[classes.block, 'container'].join(' ')}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <Title>{template.title}</Title>
      <Box className={classes.list}>
        {mapBodyTemplate(template?.body || '').map((text: string, index: number) => (
          <HistoryItem
            key={`history-${index}-item`}
            direction={index % 2 === 0 ? 'left' : 'right'}
            custom={index + 1}
          >
            {text}
          </HistoryItem>
        ))}
      </Box>
    </motion.section>
  );
}

export default HistoryList;

const useStyles = makeStyles({
  block: {
    paddingTop: '40px',
    paddingBottom: '40px',
    color: '#fefefe',
  },
  list: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    overflow: 'hidden',
  }
});
