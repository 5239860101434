import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ICompetition from "models/Competition";
import { fetchCompetitions } from "./competitionsAsync";

interface IState {
  competitions: ICompetition[] | null;
}

const initialState: IState = {
  competitions: null,
}

const competitionsSlice = createSlice({
  name: 'competitions',
  initialState,
  reducers: {
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch competitions
      .addCase(fetchCompetitions.fulfilled, (state, action:PayloadAction<ICompetition[]>) => {
        state.competitions = action.payload;
      })
  }
})

export const competitionsActions = competitionsSlice.actions;
export default competitionsSlice.reducer;