import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
// Hooks
import { useAppDispatch } from 'hooks/redux';
// Async
import { fetchCompetitions } from 'store/competitions/competitionsAsync';
// Selectors
import { selectCompetitions } from 'store/competitions/competitionsSelectors';
// Components
import Title from 'components/Title';
import CalendarItem from './CalendarItem';
// MUI
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
// Framer
import { motion } from 'framer-motion';
import Loader from 'components/Loader';
import { competitionsActions } from 'store/competitions/competitionsSlice';

const CalendarList:React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const competitions = useSelector(selectCompetitions);

  const year = dayjs().year();
  const startDate = `${year}-01-01`;
  const endDate = `${year}-12-31`;
  
  useEffect(() => {
    dispatch(fetchCompetitions({ startDate, endDate }));

    return () => {
      dispatch(competitionsActions.setInitialField('competitions'));
    }
    // eslint-disable-next-line
  }, []);

  if (!competitions) return <Loader />;
  return (
    <motion.section
      className={[classes.block, 'container'].join(' ')}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <Title>{`Календар змагань на ${year}р.`}</Title>
      <Box className={classes.list}>
        {competitions.map((competition, index) => (
          <CalendarItem key={competition._id} custom={index + 1} competition={competition} />
        ))}
      </Box>
    </motion.section>
  );
}

export default CalendarList;

const useStyles = makeStyles({
  block: {
    paddingTop: '40px',
    paddingBottom: '40px',
    color: '#fefefe',
  },
  list: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  }
});
