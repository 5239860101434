import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import IReservationRequest from "models/ReservationRequest";
import ReservationsRequestsAsync from "./reservationsRequestsAsync";

interface IState {
  reservationsRequests: IReservationRequest[] | null;
  total: number;
  params: {
    page: number;
    limit: number;
  };
}

const initialState: IState = {
  reservationsRequests: null,
  total: 0,
  params: {
    page: 1,
    limit: 20,
  },
}

const reservationsRequestsSlice = createSlice({
  name: 'reservationsRequests',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      // fetch reservations
      .addCase(ReservationsRequestsAsync.fetchReservationsRequests.pending, (state, action) => {
        state.reservationsRequests = null;
        state.total = 0;
        state.params = action.meta.arg;
      })
      .addCase(ReservationsRequestsAsync.fetchReservationsRequests.fulfilled, (state, action:PayloadAction<any>) => {
        state.reservationsRequests = action.payload.data;
        state.total = action.payload.total;
      })
      // create reservation
      .addCase(ReservationsRequestsAsync.createReservationRequest.fulfilled, (state, action:PayloadAction<IReservationRequest>) => {
        state.reservationsRequests = state.reservationsRequests
          ? [action.payload, ...state.reservationsRequests]
          : [action.payload];
        state.total = state.total + 1;
      })
      // cancel reservation
      .addCase(ReservationsRequestsAsync.cancelReservationRequest.fulfilled, (state, action) => {
        state.reservationsRequests = state.reservationsRequests
          ? state.reservationsRequests.map(reservationsRequest => reservationsRequest._id === action.payload._id ? action.payload : reservationsRequest)
          : state.reservationsRequests;
      })
  }
})

export const reservationsRequestsActions = reservationsRequestsSlice.actions;
export default reservationsRequestsSlice.reducer;
