import { FC, Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from 'config';
// Hooks
import { useAppDispatch } from 'hooks/redux';
// Async
import ReservationsRequestsAsync from 'store/reservationsRequests/reservationsRequestsAsync';
// Selectors
import { selectCurrentUser } from 'store/users/usersSelectors';
// components
import Phone from 'components/Phone';
import Title from 'components/Title';
import StyledInput from 'components/StyledInput';
// MUI
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import { MobileDatePicker } from '@mui/x-date-pickers'
import { Autocomplete, Box, Chip, Grid, MenuItem } from '@mui/material';
// utilites
import { isRequired } from 'utilities/Validation';
// Framer
import { motion } from 'framer-motion';
import { leftAnimate, opacityAnimate } from 'utilities/Framer';
// dayjs
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import useDialog from 'hooks/useDialog';
import ReservationRequestSubmitted from './ReservationRequestSubmitted';
dayjs.extend(isSameOrBefore);

const { token, chatId } = config;

interface IForm {
  startDate: any;
  endDate: any;
  firstName: string;
  lastName: string;
  phone: string;
  sectors: string[];
  carNumber: string;
  notes?: string;
}

const Reservation:FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const currentUser = useSelector(selectCurrentUser);

  const { handleSubmit, control, formState: {errors}, watch, setValue, reset } = useForm<IForm>({
    defaultValues: {
      startDate: '',
      endDate: '',
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      phone: currentUser?.phone || '',
      sectors: [],
      carNumber: currentUser?.carNumbers ? currentUser.carNumbers[0] : '',
      notes: '',
    }
  });

  const startDateWatcher = watch('startDate');
  const endDateWatcher = watch('endDate');

  const { Dialog, openDialog, closeDialog } = useDialog();

  const onSubmit = handleSubmit((data: IForm) => {
    const { startDate, endDate, sectors, ...nextData } = data;
  
    const start = dayjs(startDate).format('YYYY-MM-DD');
    const end = dayjs(endDate).format('YYYY-MM-DD');
    const startForMessage = dayjs(startDate).format('DD.MM.YYYY');
    const endForMessage = dayjs(endDate).format('DD.MM.YYYY');

    const newData = {
      ...nextData,
      startDate: start,
      endDate: end,
      sectors,
    }

    setIsLoading(true);

    dispatch(ReservationsRequestsAsync.createReservationRequest(newData))
      .unwrap()
      .then(() => {
        // dispatch(appActions.enqueueSnackbar({
        //   message: `Сектор(и) № ${sectors.join(', ')} успішно заброньовано з ${startForMessage} по ${endForMessage}`,
        //   options: { variant: NotificationStatuses.Success }
        // }));
        openDialog();
        const text = `${data.notes ? `<b>Повідомлення</b>:\n${data.notes}\n\n` : ''}<b>Дата заїзду:</b> ${startForMessage}\n<b>Дата виїзду:</b> ${endForMessage}\n<b>Сектор(и):</b> ${sectors.join(', ')}\n<b>Ім'я:</b> ${data.firstName} ${data.lastName}\n<b>Телефон</b>: +${data.phone}${data.carNumber ? `\n<b>Номер авто</b>: ${data.carNumber}` : ''}`;
        axios.get(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&parse_mode=HTML&text=${encodeURIComponent(text)}`);
        reset();
      })
      .finally(() => setIsLoading(false))
  });

  return (
    <Fragment>
      <Dialog>
        <ReservationRequestSubmitted onClose={closeDialog} />
      </Dialog>

      <motion.section
        data-container="reservation"
        className={[classes.block, 'container'].join(' ')}
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.2 }}
      >
        <Box className={classes.header}>
          <motion.div
            variants={leftAnimate}
            custom={1}
          >
            <Title>Бронювання</Title>
          </motion.div>
          <motion.p
            className={classes.subtitle}
            variants={opacityAnimate}
            custom={2}
          >
            Щоб забронювати сектор, заповніть форму нижче
          </motion.p>
        </Box>

        <form onSubmit={onSubmit} noValidate>
          <Grid container spacing={2} sx={{ pt: 4, pb: 4 }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {/* Start date */}
                  <Controller
                    control={control} name="startDate"
                    rules={{ required: isRequired }}
                    render={({ field: { onChange, value } }) => (
                      <MobileDatePicker
                        value={value || null}
                        onChange={(date: any) => {
                          onChange(date);
                          if ((endDateWatcher && dayjs(endDateWatcher).isSameOrBefore(date)) || !endDateWatcher) {
                            setValue('endDate', dayjs(date).add(1, 'day'));
                          }
                        }}
                        minDate={dayjs()}
                        label="Дата заїзду"
                        renderInput={(params: any) => (
                          <StyledInput
                            {...params}
                            required
                            fullWidth
                            error={Boolean(errors.startDate)}
                            helperText={errors.startDate ? errors.startDate.message : ''}
                            InputProps={{
                              style: {
                                color: '#fefefe'
                              }
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* End date */}
                  <Controller
                    control={control} name="endDate"
                    rules={{ required: isRequired }}
                    render={({ field: { onChange, value } }) => (
                      <MobileDatePicker
                        value={value || null}
                        onChange={(date: any) => {
                          onChange(date);
                          if (!startDateWatcher) {
                            setValue('startDate', dayjs(date).subtract(1, 'day'));
                          }
                        }}
                        minDate={startDateWatcher ? dayjs(startDateWatcher).add(1, 'day') : dayjs().add(1, 'day')}
                        label="Дата виїзду"
                        renderInput={(params: any) => (
                          <StyledInput
                            {...params}
                            fullWidth
                            required
                            error={Boolean(errors.endDate)}
                            helperText={errors.endDate ? errors.endDate.message : ''}
                            InputProps={{
                              style: {
                                color: '#fefefe'
                              }
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                {/* firstName */}
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control} name="firstName"
                    rules={{ required: isRequired }}
                    render={({ field }) => (
                      <StyledInput
                        {...field}
                        label="Ім'я"
                        fullWidth
                        required
                        error={!!errors?.firstName}
                        helperText={errors?.firstName ? errors.firstName.message : null}
                        inputProps={{
                          style: {
                            color: '#fefefe'
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                {/* lastName */}
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control} name="lastName"
                    rules={{ required: isRequired }}
                    render={({ field }) => (
                      <StyledInput
                        {...field}
                        label="Прізвище"
                        fullWidth
                        required
                        error={!!errors?.lastName}
                        helperText={errors?.lastName ? errors.lastName.message : null}
                        inputProps={{
                          style: {
                            color: '#fefefe'
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                {/* sectors */}
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control} name="sectors"
                    rules={{ required: isRequired }}
                    render={({ field }) => (
                      <StyledInput
                        fullWidth
                        {...field}
                        select
                        label="Сектор(и)"
                        required
                        error={!!errors?.sectors}
                        helperText={errors?.sectors ? errors.sectors.message : null}
                        SelectProps={{
                          multiple: true,
                          renderValue: (selected: any) => (
                            <Box sx={{ display: 'flex', gap: 0.5, maxWidth: '100%', overflow: 'hidden' }}>
                              {selected.map((value:string) => (
                                <Chip key={value} label={value} sx={{ color: '#fefefe', height: '23px' }} />
                              ))}
                            </Box>
                          ),
                          MenuProps: {
                            sx: {
                              maxHeight: { xs: '50%', md: '100%' },
                            },
                          }
                        }}
                      >
                        {sectors.map(sector => (
                          <MenuItem key={sector.value} value={`${sector.value}`}>
                            {sector.value}
                            <span style={{
                              color: '#777',
                              paddingLeft: '4px',
                              fontSize: '13px',
                            }}>{sector.label}</span>
                          </MenuItem>
                        ))}
                      </StyledInput>
                    )}
                  />
                </Grid>
                {/* carNumber */}
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control} name="carNumber"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        disablePortal
                        id="carNumber"
                        options={currentUser?.carNumbers || []}
                        isOptionEqualToValue={(option, value) => true}
                        getOptionLabel={(value: any) => value}
                        value={value || null}
                        onChange={(_:any, value:any) => {
                          onChange(value);
                        }}
                        loadingText=""
                        noOptionsText="Введіть номер авто"
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                        renderInput={(params) => 
                          <StyledInput
                            {...params}
                            onChange={(e: any) => {
                              // onChangeProperty(e);
                              onChange(e.target.value);
                            }}
                            label="Номер авто"
                            inputProps={{
                              ...params.inputProps,
                              sx: { color: '#fefefe' }
                            }}
                          />
                        }
                      />
                    )}
                  />
                </Grid>
                {/* Phone */}
                <Grid item xs={12}>
                  <Controller
                    control={control} name="phone"
                    rules={{ required: isRequired }}
                    render={({ field:{ value, onChange } }) => (
                      <Phone
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.phone)}
                        helperText={errors.phone ? (errors.phone as any).message : ''}
                        required={true}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                {/* notes */}
                <Grid item xs={12}>
                  <Controller
                    control={control} name="notes"
                    render={({ field }) => (
                      <StyledInput
                        {...field}
                        multiline
                        rows={10.4}
                        label="Повідомлення"
                        fullWidth
                        inputProps={{
                          style: {
                            color: '#fefefe'
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              Надіслати
            </LoadingButton>
          </Box>
        </form>
      </motion.section>
    </Fragment>
  );
};

export default Reservation;

const sectors = new Array(21).fill('').map((_, index) => ({ value: index, label: [10, 14, 18].includes(index) ? '(з альтанкою)' : [2, 12].includes(index) ? '(з будиночком)' : '' }));

const useStyles = makeStyles({
  block: {
    paddingTop: '16px', 
    paddingBottom: '16px',
    '@media (min-width: 600px)': {
      paddingTop: '32px', 
      paddingBottom: '32px',

    },
    '@media (min-width: 900px)': {
      paddingTop: '40px', 
      paddingBottom: '40px',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subtitle: {
    textAlign: 'center',
    color: '#fafafa',
    paddintTop: '32px',
    fontSize: '14px',
    '@media (min-width: 600px)': {
      fontSize: '16px'
    },
    '@media (min-width: 1240px)': {
      fontSize: '20px'
    }
  }
});
