import React from 'react';
// MUI
import { TransitionProps } from '@mui/material/transitions';
import {
  Dialog, DialogActions, DialogTitle, DialogContent,
  Typography, Button, Slide
} from '@mui/material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean,
  onClose: () => void,
  onSubmit: () => void,
};

const ConfirmCancelReservationRequest:React.FC<Props> = ({ open, onClose, onSubmit }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      aria-describedby="confirm-cancel-dialog-slide-description"
    >
      <DialogTitle id="confirm-cancel-dialog-title">
        Скасувати запит на бронювання?
      </DialogTitle>
      <DialogContent sx={{ minWidth: '450px', maxWidth: '100%' }} dividers>
        <Typography>
          Цю дію не можливо відмінити
        </Typography>
      </DialogContent>
      <DialogActions sx={{ pt: 4 }}>
        <Button variant="text" onClick={onClose}>Закрити</Button>
        <Button variant="contained" color="error" onClick={onSubmit}>Скасувати</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmCancelReservationRequest;
