import { FC, useState, useMemo, useCallback, MouseEvent } from 'react';
// Mui
import {
  Menu as MuiMenu, MenuProps,
  MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps
} from '@mui/material';

type MenuItemProps = MuiMenuItemProps & {
  component?: any;
  to?: string;
}

const useMenu = () => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);

  const openMenu = useCallback((event:MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    // eslint-disable-nextline
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
    // eslint-disable-nextline
  }, []);

  const Menu = useMemo(() => {
    const MenuComponent:FC<Omit<MenuProps, 'open' | 'onClose'>>  = ({ children, ...props }) => {
      const open = Boolean(anchorEl);
      if ( !open ) return null;
      return (
        <MuiMenu
          {...props}
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          PaperProps={{
            sx: {
              backgroundColor: '#262E36',
              color: '#fefefe',
            }
          }}
        >{children}</MuiMenu>
      )
    }
    return MenuComponent;
    // eslint-disable-next-line
  }, [anchorEl]);

  const MenuItem = useMemo(() => {
    const MenuItemComponent:FC<MenuItemProps>  = ({ children, ...props }) => {
      return (
        <MuiMenuItem
          {...props}
          onClick={(event:MouseEvent<HTMLLIElement>) => {
            if ( props.onClick ) props.onClick(event);
            closeMenu();
          }}
        >{children}</MuiMenuItem>
      )
    }
    return MenuItemComponent;
    // eslint-disable-next-line
  }, []);

  return {
    Menu,
    MenuItem,
    openMenu,
    closeMenu
  }
}


export default useMenu;