import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// Pages
import HomePage from 'pages/Home';
import HistoryPage from 'pages/History';
import CalendarPage from 'pages/Calendar';
import PrivateRoute from 'components/PrivateRoute';
import ProfilePage from 'pages/ProfilePage';
import ReservationsPage from 'pages/ReservationsPage';

const AppRouting:React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/history" element={<HistoryPage />} />
      <Route path="/calendar" element={<CalendarPage />} />
      <Route path="/profile" element={(
        <PrivateRoute>
          <ProfilePage />
        </PrivateRoute>
      )} />
      <Route path="/reservations" element={(
        <PrivateRoute>
          <ReservationsPage />
        </PrivateRoute>
      )} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default AppRouting;
