import React from 'react';
// Models
import IOption from 'models/Option';
// Mui
import {
  IconButton, InputAdornment,
  MenuItem,
  TextField, TextFieldProps
} from '@mui/material';
// Icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
const isMozilla = navigator.userAgent.includes('Mozilla') && navigator.userAgent.includes('Firefox');

export const Input = React.forwardRef<any, TextFieldProps>((props, ref) => {
  const [ showPassword, setShowPassword ] = React.useState<boolean>(false);

  const toggleShowPassword = () => setShowPassword((prevState:boolean) => !prevState);

  const extendedProps:TextFieldProps = {};
  if ( props.type === 'password' ){
    extendedProps['type'] = showPassword ? 'text' : 'password';
    extendedProps['autoComplete'] = props.autoComplete ? isChrome || isMozilla ? 'new-password' : props.autoComplete: '';
    extendedProps['InputProps'] = {
      ...props.InputProps,
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            onClick={toggleShowPassword}
            sx={{ color: '#eee' }}
          >{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>
        </InputAdornment>
      )
    }
  }

  const { type = 'text', onChange, ...nextProps } = props;

  const handleOnChange = (e:any) => {
    if(!onChange) return;
    if (type === 'number' && /\D/.test(e.target.value)) return; 
    onChange(e);
  }

  return (
    <TextField
      {...nextProps}
      {...extendedProps}
      ref={ref}
      fullWidth
      margin={props.margin || 'none'}
      type={type === 'number' ? 'text' : extendedProps.type || type}
      onChange={handleOnChange}
    />
  );
});

type SelectProps = TextFieldProps & {
  options: IOption[]
};

export const Select = React.forwardRef<any, SelectProps>((props, ref) => {
  return (
    <TextField
      {...props}
      ref={ref}
      select
      fullWidth
      margin={props.margin || 'normal'}
    >
      {props.options.map((option:IOption) => (
        <MenuItem
          key={`${props.name}-select-option-item-${option.value}`}
          value={option.value}
        >{option.label}</MenuItem>
      ))}
    </TextField>
  );
});
