import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const GoogleAnalytics:React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-X4ZQ2BRFHM");
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null;
};

export default GoogleAnalytics;
