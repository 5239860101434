import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import IPrice from "models/Price";
import { fetchPrices } from "./pricesAsync";

interface IState {
  prices: IPrice[];
}

const initialState: IState = {
  prices: [],
}

const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      // fetch prices
      .addCase(fetchPrices.fulfilled, (state, action:PayloadAction<IPrice[]>) => {
        state.prices = action.payload;
      })
  }
})

export const pricesActions = pricesSlice.actions;
export default pricesSlice.reducer;