import React from 'react';
// Components
import Title from 'components/Title';
// MUI
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
// Framer
import { motion } from 'framer-motion';
import { leftAnimate } from 'utilities/Framer';

const Contacts:React.FC = () => {
  const classes = useStyles();

  return (
    <motion.section
      data-container="contact-us"
      className={[classes.block, 'container'].join(' ')}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <Box className={classes.map}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1447.4029111083235!2d23.500099938963658!3d49.783387424715436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b78912414e88fc1%3A0x4279b2e0f6bb147a!2z0KLRgNC-0YTQtdC50L3QsCDQlNC-0LvQuNC90LA!5e1!3m2!1suk!2sua!4v1716311980672!5m2!1suk!2sua"
          width="100%" height="400" style={{ border: 0, borderRadius: '8px' }} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="googleMap"
        />
      </Box>
      <Box className={classes.content}>
        <motion.div
          variants={leftAnimate}
          custom={1}
        >
          <Title>Контакти</Title>
        </motion.div>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={12} sm={6}>
            <h3 className={classes.title}>Адрес:</h3>
            <p className={classes.text}>Львівська обл., с. Родатичі</p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <h3 className={classes.title}>E-mail:</h3>
            <p className={classes.text}>trophyvalley.lviv@gmail.com</p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <h3 className={classes.title}>Телефони:</h3>
            <h4 className={classes.subtitle}>Бронювання:</h4>
            <p className={classes.text}>+38 (096) 022 67 36</p>
          </Grid>
          <Grid item xs={12} sm={6} alignSelf="flex-end">
            <h4 className={classes.subtitle}>Співпраця:</h4>
            <p className={classes.text}>+38 (097) 777 59 14</p>
          </Grid>
        </Grid>
      </Box>
    </motion.section>
  );
}

export default Contacts;

const useStyles = makeStyles({
  block: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    paddingTop: '16px', 
    paddingBottom: '16px',
    '@media (min-width: 600px)': {
      paddingTop: '32px', 
      paddingBottom: '32px',

    },
    '@media (min-width: 900px)': {
      paddingTop: '40px', 
      paddingBottom: '40px',
    },
    '@media (min-width: 1240px)': {
      flexDirection: 'row',
      gap: '80px',
    },
  },
  map: {
    width: '100%',
    '@media (min-width: 1240px)': {
      width: '600px'
    },
  },
  content: {
    flexGrow: 1,
    order: -1,
    '@media (min-width: 1240px)': {
      order: 0
    }, 
  },
  title: {
    paddingTop: '8px',
    fontWeight: 500,
    color: '#fff',
    fontSize: '16px',
    '@media (min-width: 600px)': {
      fontSize: '18px'
    },
  },
  subtitle: {
    paddingTop: '8px',
    fontWeight: 500,
    color: '#fff',
    fontSize: '12px',
    '@media (min-width: 600px)': {
      fontSize: '14px'
    },
  },
  text: {
    paddingTop: '4px',
    color: '#fff',
    fontSize: '14px',
  },
});
