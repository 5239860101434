import { withStyles } from "@mui/styles";
import { Input } from "./Controls";

export default withStyles({
  root: {
    '& label': {
      color: '#fefefe',
    },
    '& label.Mui-focused': {
      color: '#CFB07A',
    },
    '& label.Mui-disabled': {
      color: '#999',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#fefefe',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CFB07A',
      },
      '&.Mui-disabled fieldset': {
        borderColor: '#999',
      },
    },
  },
})(Input);
