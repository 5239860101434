import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IUser from "models/User";
import UsersAsync from "./usersAsync";

interface IState {
  currentUser: IUser | null;
  removeImage: boolean;
}

const initialState: IState = {
  currentUser: null,
  removeImage: false,
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    removeCurrentUser: (state) => {
      state.currentUser = null;
    },
    setRemoveImage: (state, action:PayloadAction<boolean>) => {
      state.removeImage = action.payload;
    },
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch me
      .addCase(UsersAsync.fetchMe.pending, (state) => {
        state.currentUser = null;
      })
      .addCase(UsersAsync.fetchMe.fulfilled, (state, action:PayloadAction<IUser>) => {
        state.currentUser = action.payload;
      })
      // update user
      .addCase(UsersAsync.updateUser.fulfilled, (state, action:PayloadAction<IUser>) => {
        state.currentUser = action.payload;
      })
  }
})

export const usersActions = usersSlice.actions;
export default usersSlice.reducer;
