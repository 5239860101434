import { createAsyncThunk } from "@reduxjs/toolkit";
import IReservationRequest from "models/ReservationRequest";
import { appActions } from "store/app/appSlice";
import NotificationStatuses from "types/NotificationStatuses";
import HttpClient from "utilities/HttpClient";

const url = '/reservationsRequests';
const http = new HttpClient();

const ReservationsRequestsAsync = {
  fetchReservationsRequests: createAsyncThunk('reservationsRequests/fetchReservationsRequests', async(params:any , thunkApi) => {
    try {
      const { parseBody } = await http.get(url, params);
      return parseBody;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }),
  createReservationRequest: createAsyncThunk('reservationsRequests/createReservationRequest', async(data: any, thunkApi) => {
    try {
      const { parseBody } = await http.post(url, data);
      return parseBody as IReservationRequest;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }),
  cancelReservationRequest: createAsyncThunk('reservationsRequests/cancelReservationRequest', async (reservationRequestId:string, thunkApi) => {
    const { parseBody } = await http.post(`${url}/${reservationRequestId}/actions/cancel`);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Запит на бронювання скасовано', options: { variant: NotificationStatuses.Info } }));
    return parseBody as IReservationRequest;
  }),
}

export default ReservationsRequestsAsync;