import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import ISponsor from "models/Sponsor";
import { fetchSponsors } from "./sponsorsAsync";

interface IState {
  sponsors: ISponsor[];
}

const initialState: IState = {
  sponsors: [],
}

const sponsorsSlice = createSlice({
  name: 'sponsors',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      // fetch sponsors
      .addCase(fetchSponsors.fulfilled, (state, action:PayloadAction<ISponsor[]>) => {
        state.sponsors = action.payload;
      })
  }
})

export const sponsorsActions = sponsorsSlice.actions;
export default sponsorsSlice.reducer;