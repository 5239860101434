import { FC, Fragment } from 'react';
// MUI
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

type Props = {
  onClose: () => void;
}

const ReservationRequestSubmitted:FC<Props> = ({ onClose }) => {
  return (
    <Fragment>
      <DialogTitle>Запит на бронювання успішно надіслано</DialogTitle>
      <DialogContent dividers>
        <Typography>З вами зв‘яжиться адміністратор для уточнення вашого бронювання.</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
        >
          Закрити
        </Button>
      </DialogActions>
    </Fragment>
  );
}

export default ReservationRequestSubmitted;