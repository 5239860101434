import { FC, Fragment, useEffect } from 'react';
// Components
import Banner from './Banner';
import About from './About';
import Contacts from './Contacts';
import Gallery from './Gallery';
import Reservation from './Reservation';
import Socials from './Socials';
import Sponsors from './Sponsors';
import Price from './Price';
// Utilities
import { scrollToTop } from 'utilities/Utilities';

const HomePage:FC = () => {

  useEffect(() => scrollToTop(), []);

  return (
    <Fragment>
      <Banner />
      <About />
      <Price />
      <Gallery />
      <Socials />
      <Reservation />
      <Sponsors />
      <Contacts />
    </Fragment>
  );
}

export default HomePage;
