import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/redux';
// Async
import AuthAsync from 'store/auth/authAsync';
// Selectors
import { selectIsAuthenticated } from 'store/auth/authSelectors';
import { selectCurrentUser } from 'store/users/usersSelectors';
// Components
import Loader from 'components/Loader';
import Footer from 'components/Footer';
import GoogleAnalytics from 'components/GoogleAnalytics';
import Header from 'components/Header';
import Notifications from 'components/Notifications';
// Routing
import AppRouting from './App.routing';

const App:React.FC = () => {
  const dispatch = useAppDispatch();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    dispatch(AuthAsync.checkIsAuthorization());
    // eslint-disable-next-line
  }, []);

  if (isAuthenticated === null || (isAuthenticated && !currentUser)) return <Loader />;
  return (
    <React.Fragment>
      <Header />
      <main>
        <AppRouting />
      </main>
      <Footer />
      <GoogleAnalytics />
      <Notifications />
    </React.Fragment>
  );
}

export default App;
