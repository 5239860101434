import { FC, Fragment, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useAppDispatch } from 'hooks/redux';
// Async
import AuthAsync from 'store/auth/authAsync';
// MUI
import { LoadingButton } from '@mui/lab';
import {
  DialogActions, DialogContent, DialogTitle, 
  Button, Grid, IconButton, Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
// Components
import StyledInput from './StyledInput';
// Utilities
import { isEmail, isRequired } from 'utilities/Validation';

type Props = {
  onClose: () => void;
  openSignUpDialog: () => void;
}

interface IForm {
  email: string;
  password: string;
}

const SignInForm:FC<Props> = ({ onClose, openSignUpDialog }) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  
  const { control, handleSubmit, formState: { errors } } = useForm<IForm>({
    defaultValues: {
      email: '',
      password: '',
    }
  });

  const onSubmit = handleSubmit((data: IForm) => {
    setLoading(true);
    dispatch(AuthAsync.signIn(data)).unwrap()
      .then(() => onClose())
      .finally(() => setLoading(false));
  })

  return (
    <Fragment>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" color="#fff">Увійти</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon sx={{ color: '#fff' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="email"
              rules={{ required: isRequired, pattern: isEmail }}
              render={({ field }) => (
                <StyledInput
                  {...field}
                  fullWidth
                  label="E-mail"
                  required
                  error={!!errors.email}
                  helperText={errors.email?.message || ''}
                  inputProps={{
                    style: {
                      color: '#fefefe'
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="password"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <StyledInput
                  {...field}
                  fullWidth
                  label="Пароль"
                  type="password"
                  required
                  error={!!errors.password}
                  helperText={errors.password?.message || ''}
                  inputProps={{
                    style: {
                      color: '#fefefe'
                    }
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ flexWrap: 'wrap', gap: 1.5 }}>
        <Button
          color="secondary"
          onClick={openSignUpDialog}
        >
          Немає акаунту? Зареєструватись
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          onClick={onSubmit}
          loading={loading}
        >
          Увійти
        </LoadingButton>
      </DialogActions>
    </Fragment>
  );
}

export default SignInForm;
