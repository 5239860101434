import { createAsyncThunk } from "@reduxjs/toolkit";
import ICompetition from "models/Competition";
import HttpClient from "utilities/HttpClient";

const url = '/competitions';
const http = new HttpClient();

export const fetchCompetitions = createAsyncThunk('competitions/fetchCompetitions', async (params: any, thunkApi) => {
    try {
      const { parseBody } = await http.get(url, params);
      return parseBody as ICompetition[];
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
});
